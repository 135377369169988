var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container framework" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "88px" } },
              [
                _c(
                  "el-row",
                  { staticStyle: { height: "40px" }, attrs: { gutter: 24 } },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { staticStyle: { float: "right" }, attrs: { span: 4 } },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "input-with-select",
                            attrs: {
                              clearable: "",
                              placeholder: "部门名称搜索",
                              size: "small",
                            },
                            model: {
                              value: _vm.department,
                              callback: function ($$v) {
                                _vm.department = $$v
                              },
                              expression: "department",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "append",
                                type: "primary",
                                size: "small",
                                icon: "el-icon-search",
                              },
                              on: { click: _vm.ClassSearchInput },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                ref: "tb",
                staticStyle: { width: "100%", "margin-bottom": "20px" },
                attrs: {
                  data: _vm.tableData,
                  "row-key": "st_id",
                  border: "",
                  "tree-props": {
                    children: "children",
                    hasChildren: "hasChildren",
                  },
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "name",
                    label: "部门名称",
                    "min-width": "600",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", "min-width": "260", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.showBtn && scope.row.id == null
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["新建"],
                                        expression: "['新建']",
                                      },
                                    ],
                                    attrs: { type: "primary", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEdit(
                                          "newly",
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("分配条件配置")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.showBtn && scope.row.id != null
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["编辑"],
                                        expression: "['编辑']",
                                      },
                                    ],
                                    attrs: { type: "primary", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEdit("edit", scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.showBtn && scope.row.id != null
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["删除"],
                                        expression: "['删除']",
                                      },
                                    ],
                                    attrs: { type: "danger", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteConfigure(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    4196154696
                  ),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                staticStyle: { margin: "0 auto" },
                attrs: {
                  title: "分配条件配置",
                  visible: _vm.dialogFormVisible,
                  width: "1300px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogFormVisible = $event
                  },
                  close: _vm.cancel,
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "demo-ruleForm",
                    attrs: {
                      model: _vm.ruleForm,
                      rules: _vm.rules,
                      "label-width": "150px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "参与分配人员：", prop: "user_ids" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              multiple: "",
                              "collapse-tags": "",
                              clearable: "",
                              placeholder: "请选择参与分配人员",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.doSelectChange(
                                  "user_ids",
                                  "org",
                                  _vm.staffOptions
                                )
                              },
                            },
                            model: {
                              value: _vm.ruleForm.user_ids,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "user_ids", $$v)
                              },
                              expression: "ruleForm.user_ids",
                            },
                          },
                          [
                            _c(
                              "el-checkbox",
                              {
                                style: { paddingLeft: "20px" },
                                attrs: {
                                  indeterminate:
                                    _vm.checkOptions.org.isIndeterminate,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleCheckAllChange(
                                      "user_ids",
                                      "org",
                                      _vm.staffOptions,
                                      "id",
                                      $event
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.checkOptions.org.checkAll,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.checkOptions.org,
                                      "checkAll",
                                      $$v
                                    )
                                  },
                                  expression: "checkOptions.org.checkAll",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            全选\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.staffOptions, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.realname,
                                  value: item.id,
                                  disabled: item.status == 1 ? false : true,
                                },
                              })
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "分配数据来源：",
                          prop: "distribution_flag",
                        },
                      },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            model: {
                              value: _vm.ruleForm.distribution_flag,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "distribution_flag", $$v)
                              },
                              expression: "ruleForm.distribution_flag",
                            },
                          },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: 0, name: "type" } },
                              [_vm._v("单条新增成功")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-checkbox",
                              { attrs: { label: 1, name: "type" } },
                              [_vm._v("批量导入成功")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-checkbox",
                              { attrs: { label: 2, name: "type" } },
                              [_vm._v("外送接收成功")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-checkbox",
                              { attrs: { label: 3, name: "type" } },
                              [_vm._v("自动接入成功")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "分配数据方式：",
                          prop: "distribution_type",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.ruleForm.distribution_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "distribution_type", $$v)
                              },
                              expression: "ruleForm.distribution_type",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 6 } }, [
                              _vm._v("转移跟进人"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 5 } }, [
                              _vm._v("转移创建人"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 4 } }, [
                              _vm._v("共享"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "数据分配范围：",
                          prop: "distribution_range",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            on: { change: _vm.sizeChange },
                            model: {
                              value: _vm.ruleForm.distribution_range,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "distribution_range",
                                  $$v
                                )
                              },
                              expression: "ruleForm.distribution_range",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("当天全部数据"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              { attrs: { label: 1 } },
                              [
                                _vm._v(
                                  "当天数据量\n                            "
                                ),
                                _vm.showInput
                                  ? _c("el-input", {
                                      staticStyle: {
                                        width: "200px",
                                        "margin-left": "20px",
                                      },
                                      attrs: {
                                        size: "small",
                                        placeholder: "请填写要分配的数据总量",
                                        maxlength: "5",
                                      },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          return _vm.number($event)
                                        },
                                      },
                                      model: {
                                        value: _vm.ruleForm.customer_num,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "customer_num",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.customer_num",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "数据类型是否有值：",
                          prop: "is_data_type",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.ruleForm.is_data_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "is_data_type", $$v)
                              },
                              expression: "ruleForm.is_data_type",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "数据分配时间：",
                          prop: "distribution_time",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              "collapse-tags": "",
                              placeholder: "请选择数据分配时间",
                            },
                            model: {
                              value: _vm.ruleForm.distribution_time,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "distribution_time", $$v)
                              },
                              expression: "ruleForm.distribution_time",
                            },
                          },
                          _vm._l(_vm.timeOptions, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-form-item", {
                      attrs: {
                        label: "数据接收人员：",
                        prop: "structure_share",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "scope-li-class" },
                      _vm._l(_vm.bumenLabel, function (item, key) {
                        return _c(
                          "el-form-item",
                          {
                            key: key,
                            attrs: {
                              label: "分配方式：",
                              prop: "structure_share",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "bumenItem" },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100px" },
                                    attrs: { placeholder: "请选择" },
                                    on: {
                                      change: function (val) {
                                        return _vm.optisea(key, val)
                                      },
                                    },
                                    model: {
                                      value: item.distribution_way,
                                      callback: function ($$v) {
                                        _vm.$set(item, "distribution_way", $$v)
                                      },
                                      expression: "item.distribution_way",
                                    },
                                  },
                                  _vm._l(_vm.optionss, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                                _vm._v(" "),
                                item.distribution_way == 1
                                  ? _c("el-cascader", {
                                      ref: "cascaderBumen" + key,
                                      refInFor: true,
                                      staticStyle: {
                                        width: "190px",
                                        "margin-left": "20px",
                                      },
                                      attrs: {
                                        options: _vm.bumenoptions,
                                        clearable: "",
                                        "collapse-tags": "",
                                        props: {
                                          value: "suid",
                                          label: "name",
                                          multiple: true,
                                        },
                                      },
                                      on: {
                                        change: function (val) {
                                          return _vm.bumenChange(
                                            key,
                                            item.structure_share,
                                            val
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.structure_share,
                                        callback: function ($$v) {
                                          _vm.$set(item, "structure_share", $$v)
                                        },
                                        expression: "item.structure_share",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                item.distribution_way == 1
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "disabled-input-class",
                                        attrs: { disabled: true },
                                      },
                                      _vm._l(
                                        _vm.bumenLabel[key]
                                          .popularize_project_structure,
                                        function (i, key) {
                                          return _c(
                                            "span",
                                            {
                                              key: key,
                                              staticStyle: {
                                                display: "flex",
                                                "line-height": "20px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(i.label) +
                                                  "/" +
                                                  _vm._s(i.name)
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.distribution_way == 2
                                  ? _c(
                                      "el-select",
                                      {
                                        staticStyle: {
                                          "margin-left": "20px",
                                          width: "190px",
                                        },
                                        attrs: { placeholder: "请选择" },
                                        on: {
                                          change: function (val) {
                                            return _vm.changeAutoGroupFun(
                                              key,
                                              val
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.rank_group_id,
                                          callback: function ($$v) {
                                            _vm.$set(item, "rank_group_id", $$v)
                                          },
                                          expression: "item.rank_group_id",
                                        },
                                      },
                                      _vm._l(
                                        _vm.getRankGroupListData,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.group_name,
                                              value: item.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.distribution_way == 2
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "disabled-input-class",
                                        attrs: { disabled: true },
                                      },
                                      _vm._l(
                                        _vm.bumenLabel[key]
                                          .popularize_project_structure,
                                        function (i, key) {
                                          return _c(
                                            "span",
                                            {
                                              key: key,
                                              staticStyle: {
                                                display: "flex",
                                                "line-height": "20px",
                                              },
                                            },
                                            [_vm._v(_vm._s(i.name))]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "demonstration" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "require-icon-class" },
                                      [_vm._v("归属地：")]
                                    ),
                                    _vm._v(" "),
                                    _c("el-cascader", {
                                      ref: "cascaderAddress",
                                      refInFor: true,
                                      staticStyle: { width: "145px" },
                                      attrs: {
                                        options: _vm.cityArray,
                                        props: {
                                          value: "value",
                                          label: "label",
                                          multiple: true,
                                        },
                                        "collapse-tags": "",
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.createChange(
                                            item.childdren_c,
                                            key
                                          )
                                        },
                                        "visible-change": function (val) {
                                          return _vm.changeIsAllFun(val, key)
                                        },
                                      },
                                      model: {
                                        value: _vm.bumenLabel[key].childdren_c,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.bumenLabel[key],
                                            "childdren_c",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "bumenLabel[key].childdren_c",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {},
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "require-icon-class" },
                                      [_vm._v("接收数据类型：")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100px" },
                                        attrs: { placeholder: "请选择" },
                                        model: {
                                          value: item.data_type,
                                          callback: function ($$v) {
                                            _vm.$set(item, "data_type", $$v)
                                          },
                                          expression: "item.data_type",
                                        },
                                      },
                                      _vm._l(
                                        _vm.optionDataType,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.name,
                                              value: item.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("el-button", {
                                  staticStyle: {
                                    "margin-left": "1%",
                                    height: "40px",
                                  },
                                  attrs: {
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-plus",
                                    circle: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.classification(key)
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                key !== 0
                                  ? _c("el-button", {
                                      staticStyle: {
                                        "margin-left": "1%",
                                        height: "40px",
                                      },
                                      attrs: {
                                        type: "danger",
                                        size: "small",
                                        icon: "el-icon-close",
                                        circle: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.DeleteCondition(key)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    staticStyle: { "text-align": "right" },
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c("el-button", { on: { click: _vm.cancel } }, [
                      _vm._v("取消"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading.fullscreen.lock",
                            value: _vm.fullscreenLoading,
                            expression: "fullscreenLoading",
                            modifiers: { fullscreen: true, lock: true },
                          },
                        ],
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.createData("ruleForm")
                          },
                        },
                      },
                      [_vm._v("确定")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }