import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/web.dom.iterable";
import _toConsumableArray from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CustomerDistribution as _CustomerDistribution, CustomerDistributionAdd, CustomerDistributionEdit, CustomerDistributionDelete, CustomerDistributionRead, getPeople as _getPeople } from '@/api/ceshi_xiugai/whole';
import { getStructure as _getStructure, getRankGroupList, getRankGroupStructre } from '@/api/ceshi_xiugai/popularize';
import Head from '@/components/head/index';
import cities from '@/static/common_js/citys.json';
export default {
  name: 'offlineDistribution',
  components: {
    Head: Head
  },
  data: function data() {
    return _defineProperty({
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      // 系统教程
      title: '线下数据分配',
      tableData: [],
      props: {
        multiple: true
      },
      loading: true,
      fullscreenLoading: false,
      department: '',
      departmentName: '',
      timeOptions: [{
        value: '4',
        label: '04:00'
      }, {
        value: '5',
        label: '05:00'
      }, {
        value: '6',
        label: '06:00'
      }, {
        value: '7',
        label: '07:00'
      }, {
        value: '8',
        label: '08:00'
      }, {
        value: '9',
        label: '09:00'
      }],
      value: '',
      dialogFormVisible: false,
      ruleForm: {
        id: '',
        structure_id: '',
        user_ids: [],
        distribution_range: 0,
        distribution_type: 4,
        distribution_flag: [],
        customer_num: 0,
        distribution_time: '',
        structure_share: [],
        is_data_type: 1
      },
      rules: {
        user_ids: [{
          required: true,
          message: '请选择参与分配人员',
          trigger: 'change'
        }],
        // structure_share: [{ type: 'array', required: true, message: '请至少选择一个数据接收部门', trigger: 'change' }],
        distribution_flag: [{
          required: true,
          message: '请选择分配数据来源',
          trigger: 'change'
        }],
        distribution_type: [{
          required: true,
          message: '请选择分配数据方式',
          trigger: 'change'
        }],
        distribution_range: [{
          required: true,
          message: '请选择数据分配范围',
          trigger: 'change'
        }],
        distribution_time: [{
          required: true,
          message: '请选择数据分配时间',
          trigger: 'change'
        }],
        is_data_type: [{
          required: true,
          message: '请选择数据类型是否有值',
          trigger: 'change'
        }]
      },
      staffOptions: [],
      bumenoptions: [],
      //部门
      cityArray: [],
      bumenLabel: [{
        structre_id: '',
        name: '',
        addres: '',
        childdren_c: [],
        structure_share: [],
        distribution_way: 1,
        popularize_project_structure: [],
        data_type: ''
      }],
      showBtn: false,
      vals: '新建',
      showInput: false,
      checkOptions: {
        org: {
          isIndeterminate: false,
          checkAll: false
        }
      },
      optionss: [{
        id: 1,
        name: '半自动'
      }, {
        id: 2,
        name: '全自动'
      }],
      optionDataType: [{
        id: 1,
        name: '重庆留学'
      }, {
        id: 2,
        name: '社科高级'
      }],
      // 定级组列表
      getRankGroupListData: []
    }, "cityArray", []);
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.CustomerDistribution();
      this.getStructure();
      cities.map(function (item) {
        item.disabled = false;
      });
      this.cityArray = [{
        label: '不限',
        value: '0',
        disabled: false,
        pid: 1
      }].concat(_toConsumableArray(cities));
      // 获取定级组列表
      this.getRankGroupListFun();
    } else {}
  },
  methods: {
    CustomerDistribution: function CustomerDistribution() {
      var _this2 = this;
      this.loading = true;
      _CustomerDistribution({
        name: this.department
      }).then(function (respomse) {
        _this2.tableData = respomse.data;
        _this2.formatTree(_this2.tableData);
        _this2.loading = false;
      });
    },
    formatTree: function formatTree(data) {
      for (var i in data) {
        if (!data[i].children) {
          // 证明是最后一级
          data[i].showBtn = true;
        } else {
          // 证明不是最后一级，继续找
          this.formatTree(data[i].children);
        }
      }
    },
    getStructure: function getStructure() {
      var _this3 = this;
      _getStructure().then(function (respomse) {
        _this3.bumenoptions = respomse.data;
      });
    },
    getPeople: function getPeople(id) {
      var _this4 = this;
      _getPeople({
        id: id
      }).then(function (respomse) {
        var newArr = [];
        respomse.data.forEach(function (item) {
          if (item.status == 1) {
            newArr.push(item);
          }
        });
        _this4.staffOptions = newArr;
      });
    },
    handleCheckAllChange: function handleCheckAllChange(model, prop, options, id, value) {
      this.ruleForm[model] = value ? options.map(function (p) {
        return p[id || 'value'];
      }) || [] : [];
      this.checkOptions[prop].isIndeterminate = false;
    },
    // 下拉框值改变触发
    doSelectChange: function doSelectChange(model, prop, options) {
      var checkLength = this.ruleForm[model].length;
      this.checkOptions[prop].isIndeterminate = checkLength > 0 && checkLength < options.length;
      this.checkOptions[prop].checkAll = checkLength === options.length;
    },
    bumenChange: function bumenChange(i, val, only) {
      var array = this.$refs["cascaderBumen".concat(i)][0].getCheckedNodes();
      var arrayNew = array.filter(function (item) {
        return !item.hasChildren;
      });
      var popularizeArr = [];
      var name = '';
      var structre_id = '';
      arrayNew.forEach(function (item, index) {
        var popularize = {};
        // console.log('arrayNew', item);
        popularize.label = item.pathLabels[1];
        popularize.project_structure = item.path[1];
        popularize.name = item.pathLabels[2];
        popularize.user_ids = item.path[2];
        popularize.pid = item.path[0];
        popularize.structre_ids = only[index].toString();
        name = item.pathLabels[0];
        structre_id = item.path[0];
        // console.log('popularize_project_structure', popularize);
        popularizeArr.push(popularize);
      });
      this.$set(this.bumenLabel[i], 'name', name);
      this.$set(this.bumenLabel[i], 'structre_id', structre_id);
      this.$set(this.bumenLabel[i], 'popularize_project_structure', popularizeArr);
    },
    handleEdit: function handleEdit(val, key) {
      var _this5 = this;
      //  新增/新增子栏目/编辑弹窗
      this.$nextTick(function () {
        _this5.getStructure();
      });
      this.getPeople(key.st_id);
      this.ruleForm.structure_id = key.st_id;
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this5.$refs['ruleForm'].clearValidate();
      });
      if (val == 'edit') {
        this.vals = '编辑';
        this.ruleForm.id = key.id;
        CustomerDistributionRead({
          id: String(key.id)
        }).then(function (respomse) {
          var data = respomse.data;
          _this5.ruleForm.id = data.id;
          _this5.ruleForm.distribution_range = data.distribution_range;
          _this5.ruleForm.distribution_time = String(data.distribution_time);
          _this5.ruleForm.distribution_type = data.distribution_type;
          _this5.ruleForm.customer_num = data.customer_num;
          _this5.ruleForm.distribution_flag = data.distribution_flag.split(',').map(Number);
          _this5.ruleForm.is_data_type = data.is_data_type;
          _this5.ruleForm.user_ids = data.user_ids.split(',').map(Number);
          if (data.customer_num != 0) {
            _this5.showInput = true;
          } else {
            _this5.showInput = false;
          }
          if (_this5.ruleForm.user_ids.length == _this5.staffOptions.length) {
            _this5.checkOptions.org.checkAll = true;
            _this5.checkOptions.org.isIndeterminate = false;
          } else {
            _this5.checkOptions.org.checkAll = false;
            _this5.checkOptions.org.isIndeterminate = true;
          }
          var structure_share_str = data.structure_share_str;
          _this5.bumenLabel = structure_share_str;
          _this5.bumenLabel.forEach(function (item, index) {
            var bumenArr = [];
            if (item.distribution_way == 1) {
              // 部门用于回显 拼成二维数组
              item.popularize_project_structure.forEach(function (it, ind) {
                bumenArr.push(it.structre_ids.split(','));
              });
              // 归属地处理
              if (item.addres == 0 || item.addres == '') {
                item.addres = '0';
              }
              _this5.$set(_this5.bumenLabel[index], 'structure_share', bumenArr);
            } else {
              // arrGroupId.push(item.rank_group_id);
              // this.ruleForm.rank_group_id = arrGroupId.toString();
              // this.ruleForm.rank_group_id = item.rank_group_id;
              item.popularize_project_structure = item.structre_arr;
              // 归属地数据处理
              if (item.rank_group_addres == 0 || item.rank_group_addres == '') {
                item.rank_group_addres = '0';
              }
            }
            // 归属地用于回显
            var brr = [];
            _this5.cityArray.forEach(function (res_y) {
              if (item.distribution_way == 1) {
                if (item.addres == '' || item.addres == '0') {
                  brr.push(['0']);
                }
              } else {
                if (item.rank_group_addres == '' || item.rank_group_addres == '0') {
                  brr.push(['0']);
                }
              }
              res_y.children && res_y.children.forEach(function (res_z) {
                if (item.distribution_way == 1) {
                  if (item.addres.indexOf(res_z.label) != -1) {
                    brr.push([res_y.value, res_z.value]);
                  }
                } else {
                  if (item.rank_group_addres.indexOf(res_z.label) != -1) {
                    brr.push([res_y.value, res_z.value]);
                  }
                }
              });
            });
            _this5.$set(_this5.bumenLabel[index], 'childdren_c', brr);
          });
        });
      } else if (val == 'newly') {
        this.vals = '新建';
        this.bumenLabel = [{
          structre_id: '',
          name: '',
          addres: '',
          childdren_c: [],
          structure_share: [],
          distribution_way: 1,
          popularize_project_structure: [],
          data_type: ''
        }];
        this.checkOptions.org.checkAll = false;
        this.checkOptions.org.isIndeterminate = false;
      }
    },
    createData: function createData(formName) {
      var _this6 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this6.ruleForm.customer_num === '') {
            _this6.$message({
              message: '请填写要分配的数据总量',
              type: 'warning'
            });
          } else {
            // lq处理数据
            var structure_share_str = [];
            var validFlag = false;
            try {
              _this6.bumenLabel.forEach(function (item, index) {
                if (item.distribution_way == 1) {
                  var obj = {
                    structre_id: item.structre_id,
                    name: item.name,
                    popularize_project_structure: item.popularize_project_structure,
                    addres: item.addres != 0 ? item.addres : '',
                    distribution_way: item.distribution_way,
                    data_type: item.data_type
                  };
                  if (item.addres == '') {
                    throw Error('请检查半自动归属地是否填写');
                  }
                  structure_share_str.push(obj);
                } else {
                  if (item.rank_group_addres == '') {
                    throw Error('请检查全自动归属地是否填写');
                  }
                  var _obj = {
                    rank_group_id: item.rank_group_id,
                    name: item.name,
                    rank_group_addres: item.rank_group_addres != 0 ? item.rank_group_addres : '',
                    distribution_way: item.distribution_way,
                    data_type: item.data_type
                  };
                  structure_share_str.push(_obj);
                }
              });
            } catch (e) {
              validFlag = true;
              _this6.$message({
                message: e,
                type: 'warning'
              });
            }
            if (validFlag) {
              return;
            }
            _this6.ruleForm.structure_share_str = structure_share_str;
            if (_this6.vals == '新建') {
              _this6.ruleForm.user_ids = String(_this6.ruleForm.user_ids);
              _this6.ruleForm.distribution_flag = String(_this6.ruleForm.distribution_flag);
              console.log('this.ruleFormthis.ruleForm', _this6.ruleForm);
              CustomerDistributionAdd(_this6.ruleForm).then(function (respomse) {
                _this6.$notify({
                  title: '成功',
                  message: '分配条件配置成功',
                  type: 'success',
                  duration: 2000
                });
                _this6.dialogFormVisible = false;
                _this6.CustomerDistribution();
              });
            } else if (_this6.vals == '编辑') {
              _this6.fullscreenLoading = true;
              _this6.ruleForm.user_ids = String(_this6.ruleForm.user_ids);
              _this6.ruleForm.distribution_flag = String(_this6.ruleForm.distribution_flag);
              CustomerDistributionEdit(_this6.ruleForm).then(function (respomse) {
                _this6.$notify({
                  title: '成功',
                  message: '分配条件配置成功',
                  type: 'success',
                  duration: 2000
                });
                _this6.fullscreenLoading = false;
                _this6.dialogFormVisible = false;
                _this6.CustomerDistribution();
              }).catch(function () {
                _this6.fullscreenLoading = false;
              });
            }
          }
        } else {
          return false;
        }
      });
    },
    cancel: function cancel() {
      this.dialogFormVisible = false;
      this.$refs.ruleForm.resetFields();
      // this.bumenLabel = [];
      this.showInput = false;
    },
    deleteConfigure: function deleteConfigure(row) {
      var _this7 = this;
      this.$confirm('配置条件删除后不可恢复，确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        CustomerDistributionDelete({
          id: row.id
        }).then(function (res) {
          _this7.$notify({
            title: '成功',
            message: '分配条件删除成功',
            type: 'success',
            duration: 2000
          });
          _this7.$nextTick(function () {
            //获取列表方法
            _this7.CustomerDistribution();
          });
        });
      }).catch(function () {});
    },
    ClassSearchInput: function ClassSearchInput(val) {
      this.CustomerDistribution();
    },
    sizeChange: function sizeChange(val) {
      if (val == 1) {
        this.showInput = true;
        this.ruleForm.customer_num = '';
      } else {
        this.showInput = false;
        this.ruleForm.customer_num = 0;
      }
    },
    number: function number() {
      var reg = /^[1-9]\d*$/;
      if (reg.test(this.ruleForm.customer_num) == true) {
        this.ruleForm.customer_num = this.ruleForm.customer_num;
      } else {
        this.ruleForm.customer_num = '';
      }
      this.ruleForm.customer_num = this.ruleForm.customer_num.replace('.', '');
    },
    // 新增
    optisea: function optisea(index, val) {
      // 判断当前已经有一条全自动 就不让选择全自动
      // let j = 0;
      // for (let i = 0; i < this.ruleForm.bumenLabel.length; i++) {
      //     if (this.ruleForm.bumenLabel[i].distribution_way == 2) {
      //         j++;
      //         if (j == 2) {
      //             this.$message({
      //                 message: '只支持有一条全自动分配数据',
      //                 type: 'warning'
      //             });
      //             this.ruleForm.bumenLabel[index].distribution_way = 1;
      //             break;
      //         }
      //     }
      // }

      if (this.bumenLabel[index].distribution_way == 2) {
        var obj = {
          rank_group_id: '',
          name: '',
          rank_group_addres: '',
          distribution_way: 2,
          data_type: ''
        };
        this.$set(this.bumenLabel, index, obj);
      } else {
        var _obj2 = {
          structre_id: '',
          name: '',
          addres: '',
          childdren_c: [],
          structure_share: [],
          distribution_way: 1,
          popularize_project_structure: [],
          data_type: ''
        };
        this.$set(this.bumenLabel, index, _obj2);
      }
    },
    classification: function classification(key) {
      var category = {
        structre_id: '',
        name: '',
        addres: '',
        distribution_way: 1,
        popularize_project_structure: [],
        data_type: ''
      };
      this.bumenLabel.splice(Number(key) + 1, 0, category);
    },
    DeleteCondition: function DeleteCondition(key) {
      this.bumenLabel.splice(key, 1);
    },
    createChange: function createChange(item, keys) {
      var _this8 = this;
      var _this = this;
      var array2 = [];
      if (item && item.length > 0) {
        // 如果选了不限 就不能选其他城市
        // 选了其他城市 就不能选不限
        item.forEach(function (element) {
          if (element == '0') {
            _this8.cityArray.forEach(function (element1) {
              if (element1.value == '0') {
                element1.disabled = false;
              } else {
                element1.disabled = true;
              }
            });
          } else {
            _this8.cityArray.forEach(function (element1) {
              if (element1.value == '0') {
                element1.disabled = true;
              } else {
                element1.disabled = false;
              }
            });
          }
        });
      } else {
        this.cityArray.forEach(function (element) {
          element.disabled = false;
        });
        // }
      }
      setTimeout(function () {
        var array = _this.$refs['cascaderAddress'][keys].checkedNodes;
        array.forEach(function (element) {
          if (element.label == '不限') {
            array2.push('0');
          } else {
            array2.push(element.pathLabels[1]);
          }
        });
        // 判断当前这个是半自动还是全自动 参数名不同
        if (_this.bumenLabel[keys].distribution_way == 1) {
          _this8.$set(_this.bumenLabel[keys], 'addres', array2.toString());
        } else {
          _this8.$set(_this.bumenLabel[keys], 'rank_group_addres', array2.toString());
        }
      }, 0);
    },
    // 判断窗口是否显示
    changeIsAllFun: function changeIsAllFun(type, index) {
      var _this9 = this;
      if (this.bumenLabel[index].childdren_c && this.bumenLabel[index].childdren_c.length > 0) {
        this.bumenLabel[index].childdren_c.forEach(function (val) {
          if (val == 0) {
            _this9.cityArray.forEach(function (element) {
              if (element.value == 0) {
                element.disabled = false;
              } else {
                element.disabled = true;
              }
            });
          } else {
            _this9.cityArray.forEach(function (element) {
              if (element.value == 0) {
                element.disabled = true;
              } else {
                element.disabled = false;
              }
            });
          }
        });
      } else {
        this.cityArray.forEach(function (element) {
          element.disabled = false;
        });
      }
    },
    // 获取定级组列表
    getRankGroupListFun: function getRankGroupListFun() {
      var _this10 = this;
      getRankGroupList().then(function (respomse) {
        _this10.getRankGroupListData = respomse.data;
      });
    },
    // 选择全自动 存储数据
    changeAutoGroupFun: function changeAutoGroupFun(index, id) {
      var _this11 = this;
      this.$forceUpdate();
      this.$delete(this.bumenLabel[index], "popularize_project_structure");
      getRankGroupStructre({
        id: id
      }).then(function (respomse) {
        _this11.$set(_this11.bumenLabel[index], 'popularize_project_structure', respomse.data);
      });
      // this.ruleForm.rank_group_id = id;
      this.getRankGroupListData.forEach(function (item) {
        if (item.id == id) {
          _this11.$set(_this11.bumenLabel[index], 'name', item.group_name);
        }
      });
    }
  }
};